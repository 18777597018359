import authStore from "../stores/auth"
import { toastSuccess, toastError } from "../utils/myToasts"
import api from "../utils/api"
import deserialize from "../utils/deserialize"
import { get } from "lodash"

export const callSaveProfile = async user => {
  try {
    let res = await api.post("/account/store_profile", user)
    if (res.status === 401) {
      authStore.logout()
    }
    const { id, attributes } = res.data.data
    return { id, ...attributes, ...user }
  } catch (err) {
    console.error(err)
  }
}

export const getProfile = async token => {
  try {
    let res = await api.get("/account/profile", null, { token })
    const { id, attributes } = res.data.data
    return { id, ...attributes }
  } catch (err) {
    console.error(err)
  }
}

export const callAddCreditCard = async tokenId => {
  try {
    let res = await api.post("cards", { card_token: tokenId })
    if (res.status === 200) {
      toastSuccess("Card added successfully!")
    }
  } catch (err) {
    console.error(err)
    toastError(err.response.data.errors[0].detail)
  }
}

export const changePasswordPromise = async newPassword => {
  return api.put("/account/update_password", { password: newPassword })
}

export const getAllFeaturesPromise = async () => {
  return api.get("/features")
}

export const getAllProductsPromise = async () => {
  return api.get("/products")
}

export const createSubscriptionPromise = async ({
  features,
  billingCycle,
  cardId,
  promo,
  creditPayment,
  locationID,
  multilocationId,
}) => {
  return api.post("/subscriptions", {
    feature_ids: features,
    billing_cycle: billingCycle,
    card_id: cardId,
    promotion_code_id: promo,
    credit_payment: creditPayment,
    location_id: locationID,
    ...(multilocationId && {
      multilocation_product_id: multilocationId,
    }),
  })
}

export const getAllSubscriptionsPromise = async () => {
  return api.get("/subscriptions")
}

export const updateSubscriptionNickPromise = async (id, newNick) => {
  return api.put(`/subscriptions/${id}`, { id: id, nick_name: newNick })
}

export const updateSubscriptionIPWhiteList = async (id, ipWhiteList) => {
  return api.put(`/subscriptions/${id}`, { id: id, ip_whitelist: ipWhiteList })
}

export const cancelSubscriptionPromise = async id => {
  return api.get(`/subscriptions/${id}/cancel`)
}

export const getAllTransactionsPromise = async () => {
  return api.get(`/transactions`)
}

export const updateSubscriptionCardPromise = async (subscriptionId, cardId) => {
  return api.put(`/subscriptions/${subscriptionId}/update_card`, {
    card_id: cardId,
  })
}

export const getOpenCharges = async () => {
  const res = await api.get("/checkout_cart")
  const checkouts = await deserialize(res.data)
  // filter charges that 1. only one timeline "NEW" 2. No payments made
  const openCharges = checkouts
    .filter(
      ({ serviceResponse }) =>
        get(serviceResponse, "timeline.length") === 1 &&
        get(serviceResponse, "payments.length") === 0
    )
    .map(({ serviceResponse }) => ({
      url: get(serviceResponse, "hostedUrl"),
      amount: get(serviceResponse, "pricing.local.amount"),
    }))
  return openCharges
}

export const createCoinbaseCheckout = async ({
  features,
  billingCycle,
  locationID,
  amount,
  promo,
  signatureEmail,
  multilocationId,
}) => {
  try {
    const openCharges = await getOpenCharges()
    const prevCharge = openCharges.find(c => parseFloat(c.amount) === amount)
    if (prevCharge) return prevCharge

    const res = await api.post("/checkout_cart/init", {
      amount,
      promotion_code_id: promo,
      feature_ids: features,
      billing_cycle: billingCycle,
      location_id: locationID,
      electronic_signature_email: signatureEmail,
      ...(multilocationId && {
        multilocation_product_id: multilocationId,
      }),
    })

    const checkout = await deserialize(res.data)
    return { url: get(checkout, "serviceResponse.hostedUrl") }
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      "Something bad happened!"
    )
    toastError(message)
    console.error(e)
  }
}

export const createStripeCheckout = ({
  features,
  locationID,
  gre_token,
  v2 = false,
  billingCycle,
  multilocationId,
}) => {
  return api.post("/subscriptions/stripe_checkout", {
    feature_ids: features,
    location_id: locationID,
    gre_token,
    v2,
    billing_cycle: billingCycle,
    ...(multilocationId && {
      multilocation_product_id: multilocationId,
    }),
  })
}

export const upgradeToManual = id => {
  return api.post(`/subscriptions/${id}/upgrade`)
}

export const updateProxyCredential = ({ id, username, password }) => {
  return api.post(`/subscriptions/${id}/update_proxy_credentials`, {
    username,
    password,
  })
}

export const createNMICheckout = async ({
  features,
  billingCycle,
  locationID,
  paymentToken,
  cavv,
  eci,
  xid,
  cardHolderAuth,
  threeDsVersion,
}) => {
  try {
    const res = await api.post("/nmi/checkout", {
      feature_ids: features,
      billing_cycle: billingCycle,
      location_id: locationID,
      payment_token: paymentToken,
      cavv,
      eci,
      xid,
      cardholder_auth: cardHolderAuth,
      three_ds_version: threeDsVersion,
    })
    return res
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      "Something bad happened!"
    )
    toastError(message)
    console.error(e)
  }
}

export const createCoinifyCheckout = async ({
  features,
  billingCycle,
  locationID,
  multilocationId,
  signatureEmail,
}) => {
  try {
    const { data } = await api.post("/subscrition/coinify_checkout", {
      feature_ids: features,
      billing_cycle: billingCycle,
      location_id: locationID,
      electronic_signature_email: signatureEmail,
      ...(multilocationId && { multilocation_product_id: multilocationId }),
    })
    const url = get(data, "paymentWindowUrl", null)
    if (!url || url === "") {
      throw new Error("Coinify payment url empty")
    }
    return { url }
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      `Something bad happened! \n ${JSON.stringify(e?.message)}`
    )
    toastError(message)
    console.error(e)
  }
}

export const changeLocationMultiProduct = async (
  subscriptionId,
  locationId,
  proxyTypeId
) => {
  try {
    const { data } = await api.put(
      `/subscriptions/${subscriptionId}/change_location`,
      {
        location_id: locationId,
        proxy_type_id: proxyTypeId,
      }
    )
    return data
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      "Something bad happened. Please refresh the page and try agin after 5 mins"
    )
    toastError(message)
    console.error(e)
  }
}

export const createCryptomusCheckout = async ({
  features,
  billingCycle,
  locationID,
  signatureEmail,
  recurringPayments = false,
  multilocationId,
}) => {
  try {
    const { data } = await api.post("/cryptomus/create_checkout", {
      feature_ids: features,
      billing_cycle: billingCycle,
      location_id: locationID,
      electronic_signature_email: signatureEmail,
      recurring_payments: recurringPayments,
      ...(multilocationId && { multilocation_product_id: multilocationId }),
    })
    const url = get(data, "checkout_url", null)
    if (!url || url === "") {
      throw new Error("Cryptomus payment url empty")
    }
    return { url }
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      `Something bad happened! \n ${JSON.stringify(e?.message)}`
    )
    toastError(message)
    console.error(e)
  }
}

export const createBitpayCheckout = async ({
  features,
  billingCycle,
  locationID,
  signatureEmail,
  multilocationId,
}) => {
  try {
    const { data } = await api.post("/bitpay/create_checkout", {
      feature_ids: features,
      billing_cycle: billingCycle,
      location_id: locationID,
      electronic_signature_email: signatureEmail,
      ...(multilocationId && { multilocation_product_id: multilocationId }),
    })
    const url = get(data, "checkout_url", null)
    if (!url || url === "") {
      throw new Error("Cryptomus payment url empty")
    }
    return { url }
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      `Something bad happened! \n ${JSON.stringify(e?.message)}`
    )
    toastError(message)
    console.error(e)
  }
}

export const createNowPaymentsCheckout = async ({
  features,
  billingCycle,
  locationID,
  signatureEmail,
  multilocationId,
}) => {
  try {
    const { data } = await api.post("/now_payments/create_checkout", {
      feature_ids: features,
      billing_cycle: billingCycle,
      location_id: locationID,
      electronic_signature_email: signatureEmail,
      ...(multilocationId && { multilocation_product_id: multilocationId }),
    })
    const url = get(data, "checkout_url", null)
    if (!url || url === "") {
      throw new Error("Now payments payment url empty")
    }
    return { url }
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      `Something bad happened! \n ${JSON.stringify(e?.message)}`
    )
    toastError(message)
    console.error(e)
  }
}

export const createBlockonomicsCheckout = async ({
  features,
  billingCycle,
  locationID,
  signatureEmail,
  multilocationId,
}) => {
  try {
    const { data } = await api.post("/blockonomics/create_checkout", {
      feature_ids: features,
      billing_cycle: billingCycle,
      location_id: locationID,
      electronic_signature_email: signatureEmail,
      ...(multilocationId && { multilocation_product_id: multilocationId }),
    })
    const url = get(data, "payment_link", null)
    if (!url || url === "") {
      throw new Error("Blockonomics payment url empty")
    }
    return { url }
  } catch (e) {
    const message = get(
      e,
      "response.data.errors.0.title",
      `Something bad happened! \n ${JSON.stringify(e?.message)}`
    )
    toastError(message)
    console.error(e)
  }
}
